const commonPortfolioButtonClasses = `d-inline-block text-capitalize`;
const PortfolioBtnStyle = {
    backgroundColor: '#ce3461',
    color: '#ffffff',
}

const PortfolioButton = (props) => {
    if (props.externalLink) {
        return <a
            className={(props.class) ? `${props.class} ${commonPortfolioButtonClasses}` : `${commonPortfolioButtonClasses}`}
            target="_blank"
            rel="noopener noreferrer"
            style={PortfolioBtnStyle}
            href={props.link}>
            {props.children}
        </a>
    } else if (props.type) {
        return <button
            className={(props.class) ? `${props.class} ${commonPortfolioButtonClasses}` : `${commonPortfolioButtonClasses}`}
            type={props.type ?? 'button'}
            style={PortfolioBtnStyle}>
            {props.children}
        </button>
    } else {
        return <a
            className={(props.class) ? `${props.class} ${commonPortfolioButtonClasses}` : `${commonPortfolioButtonClasses}`}
            style={PortfolioBtnStyle}
            href={props.link ?? '#'}>
            {props.children}
        </a>
    }
}

export default PortfolioButton;
