import withIconsTextLinks from "components/helpers/withIconsTextLinks";
import { strReplaceAll } from "utils/string.functions";

const ContactSocialIcons = props => {
	return (props.iconsTextLinksData)
		? <ul className={`${props.class}`}>
			{props.iconsTextLinksData.map(iconDataObj => {
				const id = `#${strReplaceAll(iconDataObj.iconText).toLowerCase().trim()}`;
				return <li key={id}>
					<img src={iconDataObj.iconPath} alt="" />
					<a rel="noopener noreferrer" target="_blank" href={iconDataObj.externalLink}>{iconDataObj.iconText}</a>
				</li>
			})}
		</ul>
		: <ul className={`${props.class}`}>
			<li>not good</li>
		</ul>
}

export const WithTextLinksContactSocialIcons = withIconsTextLinks(ContactSocialIcons);
export default ContactSocialIcons;
