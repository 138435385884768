import { PortfolioProjectHeading, PortfolioText, PortfolioButton } from "components/Reuse.Components";
import { useRef } from "react";
import ProjectSubDescriptionCard from "./ProjectSubDescriptionCard";

const ProjectCard = ({ projectHeading, projectDescription, projectSubDescription, projectLink, projImg1, projImg2 }) => {

  const imgOne = useRef(null);
  const imgTwo = useRef(null);

  const returnOther = (elementName) => {
    const imgElements = [imgOne.current, imgTwo.current];
    return imgElements.filter(imgElem => imgElem.name !== elementName);
  }

  const visibleElement = (elem) => {
    elem.style.cssText = `opacity: 1; z-index: 2;`;
  }

  const invisiblElement = (elem) => {
    elem.style.cssText = `opacity: 0.2; z-index: 1;`;
  }

  const handleImgMouseOver = e => {
    const { target: { name } } = e;
    visibleElement(e.target);
    invisiblElement(returnOther(name)[0]);
  }

  const handleMouseOut = e => {
    invisiblElement(e.target);
    visibleElement(imgOne.current);
  }

  return (
    <li className='projectCard row p-0'>
      <PortfolioProjectHeading class='projectCard__mobileProjectHeading'>{projectHeading}</PortfolioProjectHeading>
      <div className="col-12 order-1 order-md-0 col-md">
        <PortfolioProjectHeading class='projectCard__heading'>{projectHeading}</PortfolioProjectHeading>
        <PortfolioText class='projectCard__description'>{projectDescription}</PortfolioText>
        <ProjectSubDescriptionCard data={projectSubDescription} />
        <PortfolioButton externalLink class='projectCard__linkBtn' link={projectLink}>project link</PortfolioButton>
      </div>
      <div className="col-12 order-0 order-md-1 col-md projectCard__imgBox">
        <img ref={imgOne} onMouseOver={handleImgMouseOver} src={projImg1} className='projectCard__img projectCard__imgOne' name="imgOne" alt="" />
        <img ref={imgTwo} onMouseOver={handleImgMouseOver} onMouseOut={handleMouseOut} src={projImg2} className='projectCard__img projectCard__imgTwo' name="imgTwo" alt="" />
      </div>
    </li>
  )
}
export default ProjectCard;
