import { WithTextLinksContactSocialIcons } from "./ContactSocialIcons";

const ContactInfo = () => {
	return (
		<div id='contactInfo'>
			<h1 className="contactInfo__mainHeading text-capitalize">contact information</h1>
			<h4 className="contactInfo__subHeading">Share your opinion or contact me through various social platforms. I will be glad to hear from you.</h4>
			<WithTextLinksContactSocialIcons class='contactInfo__socialIconsList' />
		</div>
	)
}

export default ContactInfo;
