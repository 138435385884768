import figmaIcon from "assets/tech-icons/figma.svg";
import { Container } from "components/Reuse.Components";

const Footer = () => {
  return (
    <div id='footer'>
      <Container class='p-0 footer__flex-wrapper'>
        <span className='footer__txt'>Design made with 💓 by <a rel='noopener noreferrer' target='_blank' className='footer__link' href="https://bit.ly/3afX250">Aniket Bhalla</a> in</span>
        <img className='footer__figmaIcon' src={figmaIcon} alt="Figma-icon-not-available" />
      </Container>
    </div>
  )
}

export default Footer;
