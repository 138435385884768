import { useContext } from "react";
import { FormDataContext } from "context/FormDataContext/FormDataContext";
import withFocusDetection from "components/helpers/withFocusDetection";
import asterisk from "assets/asterisk.svg";

const PortfolioInputBox = ({ id, labelName, isFocus, handleFocus, handleBlur }) => {

  const [formData, handleChange] = useContext(FormDataContext);

  return (
    <div className='portfolioInputBox'>
      <label style={{
        color: (isFocus) ? `#ce3461` : `#6c6c6c`
      }}
        className='portfolioInputBox__label'
        htmlFor={id}>
        {labelName}
        <sup>
          {
            (labelName === "your email")
              ? <img src={asterisk} alt="asterisk not available" className="asterisk" />
              : null
          }
        </sup>
      </label>
      {
        (id === "contactUserName")
          ? <input onBlur={handleBlur} onFocus={handleFocus} onChange={handleChange} className='portfolioInputBox__input' type="text" id={id} name="name" value={formData.name} />
          : (id === "contactUserEmail")
            ? <input onBlur={handleBlur} onFocus={handleFocus} onChange={handleChange} className='portfolioInputBox__input' type="email" id={id} name="email" value={formData.email} required />
            : <input onBlur={handleBlur} onFocus={handleFocus} onChange={handleChange} className='portfolioInputBox__input' type="text" id={id} name="subject" value={formData.subject} />
      }
    </div>
  )
}

export const WithFocusInputBox = withFocusDetection(PortfolioInputBox);
export default PortfolioInputBox;
