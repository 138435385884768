import withFocusDetection from "components/helpers/withFocusDetection";
import asterisk from "assets/asterisk.svg";

const PortFolioTextArea = ({ value, id, labelName, isFocus, handleFocus, handleBlur, onChangeHandle }) => {
  return (
    <div className='portfolioTextAreaBox'>
      <label
        className='portfolioInputBox__label'
        style={{
          color: (isFocus) ? `#ce3461` : `#6c6c6c`
        }}
        htmlFor={id}>
        {labelName}<sup><img src={asterisk} alt="asterisk not available" className="asterisk" /></sup>
      </label>
      <textarea onBlur={handleBlur} onFocus={handleFocus} onChange={onChangeHandle} maxLength='350' className='portfolioTextAreaBox__txtArea' type="text" name="message" id={id} value={value} required></textarea>
    </div>
  )
}

export const WithFocusTxtAreaBox = withFocusDetection(PortFolioTextArea);
export default PortFolioTextArea;
