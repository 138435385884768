import { useContext } from "react";
import { FormDataContext } from "context/FormDataContext/FormDataContext";
import { PortfolioButton } from "components/Reuse.Components/";
import { WithFocusInputBox } from "components/Reuse.Components/PortfolioInputBox";
import { WithFocusTxtAreaBox } from "components/Reuse.Components/PortfolioTextArea";
import sendMessageIcon from "assets/send-message.svg";

const ContactForm = () => {

  const [formData, handleChange, handleFormSubmit] = useContext(FormDataContext);

  return (
    <form onSubmit={handleFormSubmit} id='contactForm'>
      <div className="contactForm__flexWrapper" >
        <WithFocusInputBox id="contactUserName" labelName="your name"></WithFocusInputBox>
        <WithFocusInputBox id="contactUserEmail" labelName="your email"></WithFocusInputBox>
      </div>
      <WithFocusInputBox id="contactUserSubject" labelName="your subject"></WithFocusInputBox>
      <WithFocusTxtAreaBox onChangeHandle={handleChange} id="contactUserMessage" labelName="your message"></WithFocusTxtAreaBox>
      <PortfolioButton class='contactForm__sendBtnBox' type="submit">
        <span className='text-text-capitalize contactForm__btnMsg'>send message</span>
        <img src={sendMessageIcon} className='contactForm__btnIcon' alt="send-msg-icon-not-available" />
      </PortfolioButton>
    </form>
  )
}

export default ContactForm;
