
const ProjectSubDescriptionCard = ({ data }) => {
    return (
        <div className='projectSubDescriptionCard'>
            {data?.requirements && <div className="card__contentWrapper d-flex nowrap"><div className='projectSubDescriptionCard__subHeading'>requirements</div><div className='projectSubDescriptionCard__subText'>{data.requirements}</div></div>}
            {data?.tools && <div className="card__contentWrapper d-flex nowrap"><div className='projectSubDescriptionCard__subHeading'>tools</div><div className='projectSubDescriptionCard__subText'>{data.tools}</div></div>}
            {data?.skills && <div className="card__contentWrapper d-flex nowrap"><div className='projectSubDescriptionCard__subHeading'>skills</div><div className='projectSubDescriptionCard__subText'>{data.skills}</div></div>}
            {data?.projectAim && <div className="card__contentWrapper d-flex nowrap mb-0"><div className='d-inline projectSubDescriptionCard__subHeading lastHeading'>project's aim</div><div className='col projectSubDescriptionCard__subText'>{data.projectAim}</div></div>}
        </div>
    )
}
export default ProjectSubDescriptionCard;
