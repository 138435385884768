const ContainerFluid = props => {
    return (props.id)

        ? <section className={props.class ? `container-fluid ${props.class}` : 'container-fluid'} {...props} id={props.id}>
            {props.children}
        </section >

        : <section className={props.class ? `container-fluid ${props.class}` : 'container-fluid'} {...props}>
            {props.children}
        </section >
}

export default ContainerFluid;
