import socialIconsTextLinksData from "components/basic/Contact/socialIcons.withTextLinks.data";

const withIconsTextLinks = WrappedComponent => {

  const WithIconsTextLinks = props => {
    return <WrappedComponent iconsTextLinksData={socialIconsTextLinksData} {...props} />
  }
  return WithIconsTextLinks;
}

export default withIconsTextLinks;