import { Container, PortfolioMainHeading } from "components/Reuse.Components";
import { strReplaceAll } from "utils/string.functions";
import ProjectCard from "./ProjectCard";
import projectsData from "./projects.data";

const Projects = () => {
    return (
        <section id="projects">
            <Container>
                <PortfolioMainHeading class='projects__mainHeading'>projects</PortfolioMainHeading>
                <ul className='projects__projectList px-0 mb-0'>
                    {
                        projectsData.map(dataObj => {
                            const projectKey = strReplaceAll(dataObj.projectHeading);
                            return <ProjectCard key={projectKey} {...dataObj} />
                        })
                    }
                </ul>
            </Container>
        </section>
    )
}

export default Projects;
