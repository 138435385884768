import { createContext, useState } from "react";
import isEmail from 'validator/lib/isEmail';
import emailjs from 'emailjs-com';

export const FormDataContext = createContext();

const FormContext = ({ children }) => {

  const [formData, setformData] = useState(
    {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  );

  const resetFormData = (form) => {
    setformData({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
    form.reset();
  }

  const sendEmail = (form) => {
    emailjs.sendForm('service_ap6s7vm', 'template_7u4nuas', form, 'user_rti0MoV6wEQ94BBRV4bvu')
      .then(() => {
        alert("message sent successfully..!");
      }).catch((error) => {
        alert("some error occurred while submitting form");
      }).finally(() => resetFormData(form));
  }

  const handleChange = e => {
    const { target: { name, value } } = e;
    setformData(
      {
        ...formData,
        [name]: value
      }
    );
  }

  const isFormValid = (email, message) => (isEmail(email) && message) ? true : false

  const handleFormSubmit = e => {
    e.preventDefault();
    if (isFormValid(formData.email, formData.message)) {
      sendEmail(e.target);
    } else {
      alert("Invalid form details");
    }
  }

  return (
    <FormDataContext.Provider value={[formData, handleChange, handleFormSubmit]}>
      {children}
    </FormDataContext.Provider>
  )
}

export default FormContext;
