import gmailIcon from "assets/social-icons/gmail-icon.svg";
import locationIcon from "assets/social-icons/location-icon.svg";
import skypeIcon from "assets/social-icons/skype-icon.svg";
import githubIcon from "assets/social-icons/github-icon.svg";
import linkedinIcon from "assets/social-icons/linkedin-icon.svg";
import whatsappIcon from "assets/social-icons/whatsapp-icon.svg";

const socialIconsTextLinksData = [
  {
    iconPath: gmailIcon,
    iconText: `aniketbhalla2544@gmail.com`,
    externalLink: `mailto:aniketbhalla2544@gmail.com`
  },

  {
    iconPath: locationIcon,
    iconText: `Montreal, Canada`,
    externalLink: `https://bit.ly/3BnprSP`
  },

  {
    iconPath: skypeIcon,
    iconText: `Skype`,
    externalLink: `https://bit.ly/300P1ip`
  },

  {
    iconPath: githubIcon,
    iconText: `Github`,
    externalLink: `https://bit.ly/3akuvv3`
  },

  {
    iconPath: linkedinIcon,
    iconText: `LinkedIn`,
    externalLink: `https://bit.ly/3afX250`
  },

  {
    iconPath: whatsappIcon,
    iconText: `Whatsapp`,
    externalLink: `https://bit.ly/3uTrn31`
  }
];

export default socialIconsTextLinksData;