import { Container, ContainerFluid, PortfolioMainHeading, PortfolioText } from "components/Reuse.Components"
import FormContext from "context/FormDataContext/FormDataContext";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";

const Contact = () => {
	return (
		<ContainerFluid id="contact">
			<Container>
				<PortfolioMainHeading class='contact__meainHeading text-capitalize'>get in touch</PortfolioMainHeading>
				<PortfolioText class='contact__subHeading'>
					Want to start conversation regarding a project or have an inquiry?
					Just fill out the form below to send me message.
				</PortfolioText>
				<div className="contact__outerContentFlexWrapper">
					<ContactInfo></ContactInfo>
					<FormContext>
						<ContactForm />
					</FormContext>
				</div>
			</Container>
		</ContainerFluid>
	)
}

export default Contact;
