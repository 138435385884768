import { useRef } from "react";
import NavItem from "./NavItem";

const NavItemsList = ({ navContainerStyle, navItemStyle, dataList, positionStyle }) => {

  const mobileMenuBar = useRef(null);
  const handleItemsClick = () => {
    mobileMenuBar.current.style.cssText = `left: 100%`;
  }

  return (
    (dataList)
      ? <nav className={`${navContainerStyle}`} style={positionStyle} ref={mobileMenuBar}>
        {dataList.map(itemData => <NavItem key={`#${itemData.name}${itemData.link}`} {...itemData} navItemStyle={navItemStyle} handleItemsClick={handleItemsClick} />)}
      </nav>
      : <h1 style={{ color: 'red', fontSize: '16px' }}>NavItems.jsx: No NavItemsData found</h1>
  )
};

export default NavItemsList;
