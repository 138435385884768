import { useState } from "react";

const withFocusDetection = WrappedComponent => {

  const WithFocusDetection = props => {

    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = () => setIsFocus(isFocus || true);
    const handleBlur = () => setIsFocus(false)

    return <WrappedComponent isFocus={isFocus} handleBlur={handleBlur} handleFocus={handleFocus} {...props} />
  }
  return WithFocusDetection;
}

export default withFocusDetection;
