import hamburgerIcon from "assets/hamburger.svg";

const Hamburger = ({ width = '30px', height = 'auto', onClick }) => {
  return <img src={hamburgerIcon}
    width={width}
    height={height}
    alt="hamburger-icon-not-available"
    className="portfolioHamburger"
    onClick={onClick} />
}

export default Hamburger;
