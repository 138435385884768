import { useEffect, useState } from "react";
import { Container, ContainerFluid } from "components/Reuse.Components";
import logo from "assets/portfolio-logo.svg";
import NavItemsList from "./NavItemsList";
import navItemsData from "./Navbar.items-data";
import Hamburger from "./Hamburger";

const Navbar = () => {

    const [isScreenMobileSize, setIsScreenMobileSize] = useState(window.matchMedia("(max-width: 768.1px)").matches);
    const [isMenuButtonClicked, setIsMenuButtonClicked] = useState(false);
    const handleMenuClick = () => setIsMenuButtonClicked(!isMenuButtonClicked);

    useEffect(() => {
        const mediaQueryListObj = window.matchMedia("(max-width: 768.1px)");
        mediaQueryListObj.addEventListener('change', () => setIsScreenMobileSize(mediaQueryListObj.matches));
    }, []);

    return (
        <ContainerFluid class="navbar__container-fluid p-0" id="portfolioTop">
            <Container class="navbar__container">
                <img src={logo} alt="portfolio-logo-not-available" id="logo" />
                {
                    (isScreenMobileSize)
                        ? <Hamburger width="30px" onClick={handleMenuClick} />
                        : <NavItemsList navContainerStyle='navbar__items-container p-0' navItemStyle='navbar__nav-item' dataList={navItemsData} />
                }
                {isScreenMobileSize && <NavItemsList positionStyle={(isMenuButtonClicked) ? { left: "30%" } : { left: "100%" }} navContainerStyle='mobileNavbar__items-container p-0' navItemStyle='mobileNavbar__nav-item' dataList={navItemsData} />}
            </Container>
        </ContainerFluid>
    )
}

export default Navbar;
