import { useEffect, useState } from "react";

const NavItem = ({ link, name, navItemStyle, handleItemsClick }) => {

    const [isScreenMobileSize, setIsScreenMobileSize] = useState(window.matchMedia("(max-width: 768.1px)").matches);

    useEffect(() => {
        const mediaQueryListObject = window.matchMedia("(max-width: 768.1px)");
        mediaQueryListObject.addEventListener('change', () => setIsScreenMobileSize(mediaQueryListObject.matches));
    }, []);

    return (isScreenMobileSize)
        ? <a href={link} onClick={handleItemsClick} className={navItemStyle}>{name}</a>
        : <a href={link} className={navItemStyle}>{name}</a>
}

export default NavItem;