const navItemsData = [
    {
        name: "home",
        link: "#",
    },
    {
        name: "about",
        link: "#about",
    },
    {
        name: "projects",
        link: "#projects",
    },
    {
        name: "contact",
        link: "#contact",
    },
];

export default navItemsData;